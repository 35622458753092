import React, { useState } from 'react'
import ViewEditPassword from './ViewEditPassword';
import { TrashFill } from 'react-bootstrap-icons';
import { Table, Form, Alert } from 'react-bootstrap'
import { Modal } from 'react-bootstrap';
import { FloatingLabel } from 'react-bootstrap';
import { Decryptor } from './Encryptor';
import { Anonymizer } from './Anonymizer';
import { ref, set, remove } from 'firebase/database';
import { useAuth } from '../contexts/AuthContext'
import { database } from '../firebase'
import { confirm } from "./Confirmation";
import copyIcon from './content_copy.svg';
export default function PasswordList({ entries, showPWHint }) {

    const [query, setQuery] = useState("")
    const [info,setInfo] = useState()

    const filteredItems = entries.filter(item => {
        return Decryptor(item.title).toLowerCase().includes(query.toLowerCase()) || Decryptor(item.user).toLowerCase().includes(query.toLowerCase()) || Decryptor(item.comment).toLowerCase().includes(query.toLowerCase()) || Decryptor(item.website).toLowerCase().includes(query.toLowerCase())
    })

    const { currentUser } = useAuth()
    const [show, setShow] = useState(false);
    const [currentEntry, setCurrentEntry] = useState();

    async function deleteEntry(e) {
        if (await confirm("Are your sure?")) {
            const path = 'users/' + currentUser.uid + '/passwords/' + e.uuid;
            const dbRef = ref(database, path);
            remove(dbRef, e)
        }
    }
    function onSubmit(e) {
        const path = 'users/' + currentUser.uid + '/passwords/' + e.uuid;
        const dbRef = ref(database, path);
        set(dbRef, e)
        console.log(e)
        setShow(false)
    }

    function editPassword(e) {
        setCurrentEntry(e)
        setShow(true)
    }
    function handleClose() {
        setShow(false)
    }
    function copyToClipboard(string) {
        console.log(string)
        setInfo("Copied to clipboard")
        setTimeout(() => {
            setInfo(null)
        },1000)
    }
    return (
        <>
            <Form >
                <Form.Group id='search'>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Search for"
                        className="mb-3"
                    >
                        <Form.Control
                            // type='text' 
                            value={query}
                            type='search'
                            // ref={searchRef}
                            // placeholder="Search for"
                            onChange={e => setQuery(e.target.value)}>
                        </Form.Control>
                    </FloatingLabel>
                </Form.Group>
            </Form>
            {info && <div><Alert>{info}</Alert></div>}
            
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>User</th>
                        <th></th>
                        <th>Password</th>
                        <th></th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredItems.map((e) => {
                        return (
                            <tr key={e.uuid}>
                                <td onClick={() => editPassword(e)}>{Decryptor(e.title)}</td>
                                <td onClick={() => copyToClipboard(Decryptor(e.user))}>
                                    <div style={{display:'flex',justifyContent:'space-between'}} onClick={() => {
                                        
                                    }}>
                                        <div>{Decryptor(e.user)}</div>
                                        <div><img src={copyIcon} height="20px" alt="logo" /></div>
                                    </div>
                                </td>



                                <td onClick={() => copyToClipboard(Decryptor(e.user))}>copy</td>
                                <td onClick={() => editPassword(e)}>{showPWHint ? Anonymizer(Decryptor(e.password)) : '******'}</td>
                                <td onClick={() => copyToClipboard(Decryptor(e.password))}>copy</td>
                                <td><TrashFill onClick={() => deleteEntry(e)} color={"red"} size={20} /></td>
                            </tr>
                        )
                    })}

                </tbody>
            </Table>
            <Modal show={show} block="false">
                <ViewEditPassword entry={currentEntry} onSubmit={onSubmit} handleClose={handleClose}></ViewEditPassword>
            </Modal>

        </>
    )
}
