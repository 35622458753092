import { useRef, useState } from 'react';
import { Card, Form, Button, Container } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext'
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { COLORS } from './colors';
import { increaseCounter } from './Counter';
import { localizedMessage } from './Localisation'

const pw_light_blue = '#05BFDB'
const pw_light_green = '#00FFCA'

export default function Signup() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { signup, currentUser } = useAuth()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match!')
        }
        try {
            await signup(emailRef.current.value, passwordRef.current.value)
            
            increaseCounter("login_ok")
        }
        catch (e) {
            setError(localizedMessage(e.code))
        }
        setLoading(false)
    }
    return (<>
        <div className='text-center mb-4'>
            <span style={{ color: COLORS.green_light }}>Passwords</span>
            <span style={{ marginLeft: '10px', color: 'white', fontSize: '1.7em' }}>7</span>
        </div>
        <Container style={{ maxWidth: '500px' }}>
            <Card>
                <Card.Body>
                    <h2 className='text-center mb-4'>Sign Up</h2>
                    {currentUser && currentUser.email}
                    {error && <Alert variant='danger'>{error}</Alert>}

                    <Form onSubmit={handleSubmit}>
                        <Form.Group id='email' className='mb-4'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type='email' autoComplete="off" ref={emailRef} required></Form.Control>
                        </Form.Group>
                        <Form.Group id='password' className='mb-4'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type='password' ref={passwordRef} required></Form.Control>
                        </Form.Group>
                        <Form.Group id='passwordconfirm'>
                            <Form.Label>Password Confirmation</Form.Label>
                            <Form.Control type='password' ref={passwordConfirmRef} required></Form.Control>
                        </Form.Group>

                        <div className='text-center'>
                            <Button
                                disabled={loading}
                                className='w-50 mt-4'
                                type='submit'
                                style={{ backgroundColor: COLORS.bg_dark, borderColor: COLORS.pw_light_green }}>Sign Up</Button>
                        </div>

                    </Form>

                </Card.Body>
            </Card >
        </Container>
        <div className='text-center mt-2' style={{ color: COLORS.blue_light, fontSize: '.8em' }}>
            Already have an account or want to use Social Media? <Link to="/login" style={{ color: COLORS.green_light }}>Login / Social Media</Link>
        </div>
        <div className='text-center mt-2' style={{ color: pw_light_blue, fontSize: '.6em' }}>
            <Link to="https://passwords7.com" style={{ color: pw_light_green }}>(c) Passwords7.com</Link>
        </div>
    </>

    )
}