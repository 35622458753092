import { COLORS } from './colors';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from '../contexts/AuthContext'
import Signup from './Signup';
import Login from './Login';
import MainScreen from './MainScreen';
import './App.css'
import Settings from './Settings';
import PluginConfirmation from './PluginConfirmation';
import ConfirmationSuccess from './ConfirmationSuccess';
import WaitingForEmailVerification from './WaitingForEmailVerification';
import PasswordRecovery from './PasswordRecovery';
import PleaseCheckYourMail from './PleaseCheckYourMail';
export default function App() {


  return (
    
        <div className='App'>
          {/* <input type="hidden" id="confirmation_code" value="4712colonia7"></input> */}
            <div style={{ backgroundColor: COLORS.bg_dark, minHeight: '100vh' }}>
         <Router>
          <AuthProvider>
            <Routes>
              <Route exact path="/" Component={MainScreen} />
              <Route exact path="/main" Component={MainScreen} />
              <Route path="/login" Component={Login} />
              <Route path="/signup" Component={Signup} />
              <Route path="/plugin_confirmation" Component={PluginConfirmation} />
              <Route path="/confirmation_success" Component={ConfirmationSuccess} />
              <Route path="/please_check_mail" Component={PleaseCheckYourMail} />
              <Route path="/password_recovery" Component={PasswordRecovery} />
              <Route path="/settings" Component={Settings} />
            </Routes>
          </AuthProvider>
        </Router> 
        </div>
      </div>
  )
}
