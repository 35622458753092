import React, { useRef, useState } from 'react';
import { Card, Form, Button, Container } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext'
import { Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import { increaseCounter } from './Counter';
import { localizedMessage } from './Localisation'
import './App.css'
const pw_bg_dark = '#0A4D68'
const pw_light_blue = '#05BFDB'
const pw_light_green = '#00FFCA'

export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login, currentUser, loginUpWithGoogle, loginUpWithApple } = useAuth()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function googleLogin(e) {
        increaseCounter("google_login_started")
        e.preventDefault()
        try {
            setLoading(true)
            await loginUpWithGoogle()
            increaseCounter("acc_created")
            navigate("/")
        }
        catch (e) {
            setError(e.toString())
        }
        setLoading(false)
    }

    async function appleLogin(e) {
        increaseCounter("apple_login_started")
        e.preventDefault()
        try {
            setLoading(true)
            await loginUpWithApple()
            increaseCounter("acc_created")
            navigate("/")
        }
        catch (e) {
            setError(e.toString())
        }
        setLoading(false)
    }

    async function emailLogin(e) {
        increaseCounter("email_login_started")
        e.preventDefault()
        try {
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            increaseCounter("acc_created")
            navigate("/")
        }
        catch (e) {
            setError(localizedMessage(e.code))
        }
        setLoading(false)
    }

    // return(<>test123</>)
    return (<>
        <div className='text-center mb-4'>
            <span style={{ color: pw_light_green }}>Passwords</span>
            <span style={{ marginLeft: '10px', color: 'white', fontSize: '1.7em' }}>7</span>
        </div>
        <Container style={{ maxWidth: '500px' }}>
            <Card className='w-100'>
                <Card.Body>
                    <h2 className='text-center mb-4'>Login</h2>
                    {currentUser && currentUser.email}
                    {error && <Alert variant='danger'>{error}</Alert>}

                    <Form onSubmit={emailLogin}>
                        <Form.Group id='email' className='mb-4'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type='email' autoComplete="off" ref={emailRef} required></Form.Control>
                        </Form.Group>
                        <Form.Group id='password' >
                            <Form.Label>Password</Form.Label>
                            <Form.Control type='password' autoComplete="off" ref={passwordRef} required></Form.Control>
                        </Form.Group>
                        <div className='text-center'>
                            <Button
                                disabled={loading}
                                className='w-50 mt-4'
                                type='submit'
                                style={{ backgroundColor: pw_bg_dark, borderColor: pw_light_green }}>Login</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card >
        </Container>

        <div className='text-center mt-2' style={{ color: pw_light_blue, fontSize: '.8em' }}>
            Forgot your password? Follow this link to <a style={{color:pw_light_blue}} href='/password_recovery'>recover your password</a>
        </div>

        <div className='text-center mt-2' style={{ color: pw_light_blue, fontSize: '.8em' }}>
            or login/sign up using
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }} >
            <img src="./google.png" alt="..." height="50px" onClick={googleLogin} className="rounded-circle shadow-lg d-flex align-items-center justify-content-center "></img>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}>
            <img src="./apple.png" alt="..." onClick={appleLogin} height="40px" />
        </div>

        <div className='text-center mt-2' style={{ color: pw_light_blue, fontSize: '.8em' }}>
            Need an account? <Link to="/signup" style={{ color: pw_light_green }}>Sign up</Link>
        </div>

        <div className='text-center mt-2' style={{ color: pw_light_blue, fontSize: '.6em' }}>
            <Link to="https://passwords7.com" style={{ color: pw_light_green }}>(c) Passwords7.com</Link>
        </div>
    </>
    )
}

