function localizedMessage(code) {
    console.log(code)
    switch (code) {
        case "auth/wrong-password": return "Username or password incorrect"
        case "auth/user-not-found": return "User does not exist"
        case "auth/email-already-in-use": return "Email already has an account. Try to login."
        case "auth/too-many-requests": return "Server busy. Please try again in 5 minutes."
        default: return "An unknown error occured please contact our support."
    }
}

module.exports = { localizedMessage }