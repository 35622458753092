import React from 'react'
import { Button, Card, Container } from 'react-bootstrap'
import { useAuth } from "../contexts/AuthContext"

const pw_light_green = '#00FFCA'
const pw_bg_dark = '#0A4D68'

export default function PleaseCheckYourMail() {
    const { currentUser } = useAuth()
    function resendLink() {
        currentUser.sendEmailVerification({
            handleCodeInApp:true,
            url:"http://localhost:3000/"
        })
    }
  return (
    <div>
      <div className='text-center mb-4'>
            <span style={{ color: pw_light_green }}>Passwords</span>
            <span style={{ marginLeft: '10px', color: 'white', fontSize: '1.7em' }}>7</span>
        </div>
        <Container style={{ maxWidth: '500px' }}>
            <Card className='w-100'>
                <Card.Body>
                    <h2 className='text-center mb-4'>Info</h2>
                    <div>Please Check your Mail for a verification Link</div>
                    <div><br /></div>
                    <Button style={{ backgroundColor: pw_bg_dark, borderColor: pw_light_green }} onClick={resendLink}>resend link</Button>
                </Card.Body>
            </Card >
        </Container>
        
      
    </div>
  )
}
