import { useState, useEffect } from 'react'
import { Button, Modal, Table, Form } from 'react-bootstrap';
import { auth } from '../firebase'
import { database } from '../firebase'
import { ref, onValue, set, remove, get } from 'firebase/database';
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../contexts/AuthContext.js';
import moment from 'moment';


export default function Settings() {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const [showDeletionModal, setShowDeletionModal] = useState(false)
  const [confirmationText, setConfirmationText] = useState("")
  const [emailNotificationOnSignIn, setEmailNotificationOnSignIn] = useState(true)
  const [showPWHint, setShowPWHint] = useState(true)
  const [uid, setUID] = useState("")
  const [allowPasswordRecovery, setAllowPasswordRecovery] = useState(true)
  const [userLog, setUserLog] = useState([])
  const [authorizedPlugins, setAuthorizedPlugins] = useState([
    {
      id: "4711",
      name: "Chrome Plugin 4711"
    },
  ])

  useEffect(() => {
    if (!currentUser) return
    setUID(currentUser.uid)

    onValue(ref(database, 'users/' + currentUser.uid + '/settings/show_pw_hint'), (snap) => {
      let showPWHint = snap.val() ?? true;
      setShowPWHint(showPWHint)
    })
    onValue(ref(database, 'users/' + currentUser.uid + '/settings/allow_password_recovery'), (snap) => {
      setShowPWHint(snap.val() ?? true)
    })

    onValue(ref(database, 'users/' + currentUser.uid + '/settings/email_on_signin'), (snap) => {
      let value = snap.val() ?? true;
      setEmailNotificationOnSignIn(value)
    })

    onValue(ref(database, 'users/' + currentUser.uid + '/settings/authorised_plugins'), (snap) => {
      console.log(snap.val())
      var results = []
      snap.forEach(item => {
        results.push(item.val())
      })
      setAuthorizedPlugins(results)
    })

    get(ref(database, 'user_logs/' + currentUser.uid)).then(snap => {
      var content = []
      snap.forEach(item => {
        content.push(item.val())
      })
      console.log("userLog:", content)
      setUserLog(content)
    })
  }, [currentUser])


  function deleteAccount() {
    setShowDeletionModal(true)
  }

  async function reallyDeleteAccount() {
    console.log("initiating Account Deletion")
    // push(ref(database,'global_logging',moment().format('YYYY-MM-DD HH:mm:ss')+ "initiating Account Deletion"))
    const uid = currentUser.uid
    if (uid) {
      console.log("Deleting account for:", uid)
      set(ref(database, 'account_deletions/' + uid), { uid: uid })
    }
    else {
      console.log("ERROR deleting account - no uid")
    }
    logGlobal('User has deleted account.')
    setShowDeletionModal(false)
    if (uid.length > 10) {
      remove(ref(database, 'users/' + uid))
    }

    await auth.signOut()
    // TODO: Mail Infor ueber successful login geht nicht.

  }
  function saveAndClose() {
    console.log("Saving settings")
    const path = 'users/' + currentUser.uid + '/settings'
    const dbRef = ref(database, path);
    console.log(showPWHint)
    set(dbRef, {
      'show_pw_hint': showPWHint,
      'authorised_plugins': authorizedPlugins,
      'email_on_signin': emailNotificationOnSignIn,
      'allow_password_recovery': allowPasswordRecovery
    })
    navigate("/")
  }

  function logGlobal(message) {
    const today = moment().format("YYYY-MM-DD")
    const time = moment().format("YYYY-MM-DD HH:mm:ss")
    const messageLong = "[PW7] " + time + " " + message
    const uuid = uuidv4()
    console.log("today:", messageLong)
    set(ref(database, 'logging_global/' + today + '/' + uuid), { message: messageLong })
  }
  function handleClose() {
    setShowDeletionModal(false)
  }


  return (<div style={{ marginLeft: '100px', marginRight: '100px', alignContent: 'center', alignSelf: 'center' }}>

    {/* remove this into a logo element */}
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <img src='./passwords7icon.png' alt='find uns on the app store'></img>
      {/* <div >
        <span style={{ color: COLORS.green_light }}>Passwords</span>
        <span style={{ marginLeft: '10px', color: 'white', fontSize: '1.7em' }}>7</span>

      </div>
      <div style={{ color: COLORS.green_light }}>Passwords</div>
      <div style={{ color: COLORS.green_light }}>7</div>
      <div>logout</div> */}
      <div style={{ display: 'flex', gap: '10px' }}>

      </div>

    </div>
    <div style={{ display: 'flex', justifyContent: 'end' }}><Button variant="success" onClick={() => {
      navigate("/")
    }}>Close</Button></div>
    <p style={{ color: 'white' }}>Click on value to change settings</p>
    <p style={{ color: 'white' }}>{uid}</p>
    <div className="d-flex align-items-center justify-content-center mb-3" >

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Settings</th>
            <th></th>

          </tr>
        </thead>
        <tbody>

          <tr key={1}>
            <td>Show Password hint</td>
            <td>
              <label className="switch">
                {
                  <input type="checkbox" checked={showPWHint} onChange={(val) => {
                    console.log("Switched.", val.target.checked)
                    setShowPWHint(val.target.checked)
                  }} />
                }
                <span className='slider round'></span>
              </label></td>
          </tr>
          <tr key={2}>
            <td>Allow Password Recovery</td>
            <td>
              <label className="switch">
                {
                  <input type="checkbox" checked={allowPasswordRecovery} onChange={(val) => {
                    console.log("Switched.", val.target.checked)
                    setAllowPasswordRecovery(val.target.checked)
                  }} />
                }
                <span className='slider round'></span>
              </label></td>
          </tr>
        </tbody>
      </Table>
    </div>

    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Notification Settings</th>
          <th></th>

        </tr>
      </thead>
      <tbody>
        <tr key={1}>
          <td>Notify about each login to Passwords7</td>
          <td>
            <label className="switch">
              {
                <input type="checkbox" checked={emailNotificationOnSignIn} onChange={(val) => {
                  console.log("Switched.", val.target.checked)
                  setEmailNotificationOnSignIn(val.target.checked)
                }} />
              }
              <span className='slider round'></span>
            </label></td>
        </tr>

      </tbody>
    </Table>

    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Authorized Plugins</th>
          <th></th>

        </tr>
      </thead>
      <tbody>
        {authorizedPlugins.map(item => {
          return <tr key={item.id}>
            <td >{item.name} (created: {item.created_at})</td>
            <td >
              <button onClick={() => {
                console.log("deleting plugin with id: ", item.id)
                const plugInPath = 'users/' + currentUser.uid + "/settings/authorised_plugins/" + item.id
                remove(ref(database, plugInPath))
              }}>delete</button></td>
          </tr>
        })}

      </tbody>
    </Table>

    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Audit Log</th>
          <th></th>

        </tr>
      </thead>
      <tbody>
        {userLog.map(item => {
          return <tr key={item}>
            <td style={{textAlign:'start'}} >{item} </td>
            <td >
            </td>
          </tr>
        })}

      </tbody>
    </Table>

    <Button variant="danger" onClick={deleteAccount}>
      Delete Account
    </Button>
    <br></br>
    <br></br>
    <Button variant="success" onClick={saveAndClose}>
      Save & Close
    </Button>
    <Modal show={showDeletionModal} block="false">
      <Modal.Header closeButton onClick={(handleClose)}>
        <Modal.Title>Account Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Deletion of account is irrevocable! Be careful!</div>
        <div>To confirm. Please type "I AM SURE" in the following field.</div>
        <Form>
          <Form.Group controlId="title" className='mb-3'>
            <Form.Control
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}>
            </Form.Control>
          </Form.Group>
        </Form>

        <br></br>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={reallyDeleteAccount} variant="danger" disabled={confirmationText !== "I AM SURE"}>YES, I want to delete my account</Button>
          <br></br>
          <Button variant="success">Cancel</Button>
        </div>

      </Modal.Body>
    </Modal>
  </div>
  )
}
