
const CryptoJS = require('crypto-js');

// const oldEncryptedBase64 = "L8n+7cckD1vxUje/4xgjpA=="
// // const key = "This 32 char key have 256 bits.."
// const key = CryptoJS.enc.Utf8.parse("This 32 char key have 256 bits..");
// const key16 = "This 32 char key "
// console.log(oldEncryptedBase64)
// const iv = CryptoJS.enc.Utf8.parse(key16)
// // const xxx = CryptoJS.mode.CBC.Encryptor.create("Willrein9", iv)
// const xxx = CryptoJS.AES.encrypt("Willrein9",key, {
//   iv:iv,
//   mode:CryptoJS.mode.CBC,
// })
// console.log(xxx.toString())


export function Encryptor(string) {
    const key = CryptoJS.enc.Utf8.parse("This 32 char key have 256 bits..");
    const key16 = "This 32 char key "
    const iv = CryptoJS.enc.Utf8.parse(key16)
    const xxx = CryptoJS.AES.encrypt(string,key, {
      iv:iv,
      mode:CryptoJS.mode.CBC,
    })
    console.log(string)
    return xxx.toString()
}

export function Decryptor(encoded) {
    
    if (encoded) {
        const key = CryptoJS.enc.Utf8.parse("This 32 char key have 256 bits..");
        const key16 = "This 32 char key "
        const iv = CryptoJS.enc.Utf8.parse(key16)
        const xxx = CryptoJS.AES.decrypt(encoded,key, {
          iv:iv,
          mode:CryptoJS.mode.CBC,
        })
        try {
          return xxx.toString(CryptoJS.enc.Utf8)
        } catch {
          return "777"
        }
        
        // 
       
        
    } else {
        // console.log(`not decoding`)
        return ""
    }

}