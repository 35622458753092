import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { auth } from '../firebase'
// import { useAuth } from '../contexts/AuthContext'
import { database } from '../firebase'
import { ref, onValue, set } from 'firebase/database';
import AddPassword from './AddPassword';
import PasswordList from './PasswordList.jsx';
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import { Encryptor } from './Encryptor';
import moment from 'moment';
import { COLORS } from './colors';
import { Decryptor } from './Encryptor';
import { increaseCounter } from './Counter.js';
import { useAuth } from '../contexts/AuthContext.js';
import NoEntriesLine from './NoEntriesLine.jsx';

// TODO: On authentication: password verification and if verified, set /settings/show_pw_hint to true.
// TODO: Account Deletion.
export default function MainScreen() {
  const [show, setShow] = useState(false);
  const { currentUser } = useAuth()
  const [data, setData] = useState([])
  const [showPWHint, setShowPWHint] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!currentUser) {
      return
    }
    onValue(ref(database, 'users/' + currentUser.uid + '/settings/show_pw_hint'), (snap) => {
      let showPWHint = snap.val() ?? true;
      setShowPWHint(showPWHint)
    })

    const path = 'users/' + currentUser.uid + '/passwords'
    const dbRef = ref(database, path);

    onValue(dbRef, (snapshot) => {
      let array = []
      snapshot.forEach(element => {
        const item = element.val()
        item.uuid = element.key
        array.push(item)
      })
      setData(array)
    })
  }, [currentUser])


  function logout() {
    increaseCounter("logout")
    // navigate("/login")
    auth.signOut()
  }
  function settings() {
    
    navigate("/settings")
  }


  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const onLoginFormSubmit = (e) => {
    console.log(`Title: ${e.title}`)
    console.log(`Username: ${e.user}`)
    console.log(`Password: ${e.password}`)
    console.log(`Website: ${e.website}`)
    console.log(`Comment: ${e.comment}`)
    console.log(`Favorite: ${e.favorite}`)
    const uuid = uuidv4()
    e.uuid = uuid
    const path = 'users/' + currentUser.uid + '/passwords/' + uuid;
    const dbRef = ref(database, path);
    const map = {
      'title': Encryptor(e.title),
      'user': Encryptor(e.user),
      'password': Encryptor(e.password),
      'website': Encryptor(e.website),
      'comment': Encryptor(e.comment),
      'isFavourite': e.favorite,
      'uuid': e.uuid,
      'created': moment().format('yyyy-MM-DD HH:mm:ss'),
      'updated': '',
    }
    console.log(uuid)
    set(dbRef, map)
    setShow(false)
  }

  return (<div style={{ marginLeft: '100px', marginRight: '100px', alignContent: 'center', alignSelf: 'center' }}>

    {/* remove this into a logo element */}
    <div style={{ display: 'flex', alignItems:'center', justifyContent:'space-between' }}>
    <img src='./passwords7icon.png' alt='find uns on the app store'></img>
      {/* <div >
        <span style={{ color: COLORS.green_light }}>Passwords</span>
        <span style={{ marginLeft: '10px', color: 'white', fontSize: '1.7em' }}>7</span>

      </div>
      <div style={{ color: COLORS.green_light }}>Passwords</div>
      <div style={{ color: COLORS.green_light }}>7</div>
      <div>logout</div> */}
      <div style={{ display: 'flex' ,gap:'10px'}}>
      <div style={{color:'white', cursor:'pointer'}} onClick={logout}>Logout</div>
      <div style={{color:'white', cursor:'pointer'}} onClick={settings}>Settings</div>
      </div>
      
    </div>

    <div className="d-flex align-items-center justify-content-center mb-3" >
      <Button variant="success" onClick={handleShow}>
        Add Entry
      </Button>
    </div>

    <PasswordList showPWHint={showPWHint} entries={data.sort((a, b) => Decryptor(a.title).toLowerCase().localeCompare(Decryptor(b.title).toLowerCase()))}></PasswordList>
    <NoEntriesLine entries={data}></NoEntriesLine>
    
    <br></br>

    <Modal show={show} block="false">
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title>Add Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddPassword onSubmit={onLoginFormSubmit} />
      </Modal.Body>
    </Modal>
  </div>
  )
}
