import React, { useState } from 'react'
import ViewEditPassword from './ViewEditPassword';
import { TrashFill } from 'react-bootstrap-icons';
import { Table, Form } from 'react-bootstrap'
import { Modal } from 'react-bootstrap';
import { FloatingLabel } from 'react-bootstrap';
import { Decryptor } from './Encryptor';
import { Anonymizer } from './Anonymizer';
import { ref, set, remove } from 'firebase/database';
import { useAuth } from '../contexts/AuthContext'
import { database } from '../firebase'
import { confirm } from "./Confirmation";

export default function NoEntriesLine({ entries }) {

    const [query, setQuery] = useState("")

    const filteredItems = entries.filter(item => {
        return Decryptor(item.title).toLowerCase().includes(query.toLowerCase()) || Decryptor(item.user).toLowerCase().includes(query.toLowerCase()) || Decryptor(item.comment).toLowerCase().includes(query.toLowerCase()) || Decryptor(item.website).toLowerCase().includes(query.toLowerCase())
    })

    const { currentUser } = useAuth()
    const [show, setShow] = useState(false);
    const [currentEntry, setCurrentEntry] = useState();

    async function deleteEntry(e) {
        if (await confirm("Are your sure?")) {
            const path = 'users/' + currentUser.uid + '/passwords/' + e.uuid;
            const dbRef = ref(database, path);
            remove(dbRef, e)
        }
    }
    function onSubmit(e) {
        const path = 'users/' + currentUser.uid + '/passwords/' + e.uuid;
        const dbRef = ref(database, path);
        set(dbRef, e)
        console.log(e)
        setShow(false)
    }

    function editPassword(e) {
        setCurrentEntry(e)
        setShow(true)
    }
    function handleClose() {
        setShow(false)
    }
    return (
        <>
        {entries.length === 0 ? <div style={{backgroundColor:'light-gray', borderRadius:'10px', color:'white'}}>No Entries so far. Click on "Add Entry" to add your first password entry.</div>: <div></div>}
        </>
    )
}
