import React, { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import { ref, set } from 'firebase/database';
import { database } from '../firebase';
import moment from 'moment';
export default function PluginConfirmation() {
    const navigate = useNavigate()
    const [confirmationCode, setConfirmationCode] = useState("")
    const [uid, setUid] = useState("")
    const { currentUser } = useAuth()
    useEffect(() => {

        // to be sure user is not somehow routed here without being authenticated, we check if use is authorized.
        console.log("Current UID:", currentUser?.uid)
        // TODO: Hier wirklich einen starken CODE verwenden und nicht nur die UUID
        // TODO: Verhindere ein Reload!
        const uuid = uuidv4()
        setConfirmationCode(uuid)
        setUid(currentUser?.uid ?? "unknown")
        // lets set
    }, [currentUser])

    function handleSubmit(e) {
        e.preventDefault()
        // we need to fill in under settings
        const uuid = uuidv4()
        console.log("Granting Access for ", currentUser.uid)
        const path = 'users/' + currentUser.uid + '/settings/authorised_plugins/' + uuid
        console.log(path)
        const dbRef = ref(database, path);
        try {
            set(dbRef, {
                'created_at': moment().format("YYYY-MM-DD HH:mm"),
                'confirmation_code': confirmationCode,
                'uid': uid,
                'name': 'Chrome Plug-In',
                'id':uuid
            })
            navigate("/confirmation_success")

            // add to Mail Q
            
            const mailId = uuidv4()
            const mailPath = 'mailqueue/' + mailId
            set(ref(database, mailPath),{
                'uuid':mailId,
                'time_created':moment().format("YYYY-MM-DD HH:mm:ss"),
                'message': "Password7 Chrome Plug-In was just activated. \nIf this wasn't your, login to your account https://manager.passwords7.com, goto Settings on the top right corner and remove the plugin authentication immediately.",
                'recipient': currentUser.email,
                'uid': uid,
                'title':'[PW7]',
                'origin': 'Chrome Plug-In'
            })
        } catch (error) {
            console.log(error)
        }


    }

    return (
        <div>
            <p className='testclass'>test</p>

            <form onSubmit={handleSubmit} autoComplete='off'>

                {/* This part is so the plugin can parse the confirmation code and save it into the chrome.store */}
                <input type="text" id="confirmation_code" value={confirmationCode} readOnly></input>
                <input type="text" id="uid" value={uid} readOnly></input>
                <button type="submit">Grant Plug Access</button>

            </form >

            PluginConfirmation
        </div>
    )
}
