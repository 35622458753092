import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { FloatingLabel } from 'react-bootstrap';
import React, { useState } from 'react'
import { EyeFill, StarFill } from 'react-bootstrap-icons';
import { Encryptor, Decryptor } from './Encryptor';
import { Link } from 'react-router-dom';



export default function ViewEditPassword({ entry, onSubmit, handleClose }) {
    const [uuid] = useState(entry.uuid)
    const [title, setTitle] = useState(Decryptor(entry.title));
    const [user, setUser] = useState(Decryptor(entry.user));
    const [password, setPassword] = useState(Decryptor(entry.password)); // TODO: Show/Hide
    const [website, setWebsite] = useState(Decryptor(entry.website));
    const [comment, setComment] = useState(Decryptor(entry.comment));
    const [favorite, setFavorite] = useState(false);
    const [showPasswort, setShowPassword] = useState(false)

    const [isEditing, setEditing] = useState(false)

    function toggleEditing() {
        setEditing(!isEditing)
    }

    function toggleFav() {
        setFavorite(!favorite)
    }
    function toggleShowPassword() {
        setShowPassword(!showPasswort)
    }
    function handleSubmit(e) {
        e.preventDefault()
        if (isEditing) {
            const foo = {
                'uuid': uuid,
                'title': Encryptor(title),
                'user': Encryptor(user),
                'password': Encryptor(password),
                'website': Encryptor(website),
                'comment': Encryptor(comment),
                'favorite': favorite,
            }
            onSubmit(foo)
        }
    }

    return (<>
        
        <Modal.Header closeButton onClick={handleClose}>
            <Modal.Title >{isEditing ? "Edit Password" : "View Password"} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit} autoComplete='off'>
                <div className='mb-4'  style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Link style={{ fontSize: '0.8em' }} onClick={toggleEditing}>{isEditing ? "close edit" : "edit"}</Link>
                </div>
                <div className='mb-4' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StarFill onClick={toggleFav} color={favorite ? "orange" : "grey"} size={30} />
            </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {isEditing ? "" : "Click to copy "}
                </div>

                <Form.Group controlId="title">
                    <FloatingLabel controlId="username" label="Title" className="mb-3">
                        <Form.Control
                            disabled={!isEditing}
                            autoCorrect='off'
                            placeholder="Username"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)} />
                    </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="username">
                    <FloatingLabel controlId="username" label="Username" className="mb-3">
                        <Form.Control
                            disabled={!isEditing}
                            autoCorrect='off'
                            placeholder="Username"
                            value={user}
                            onChange={(e) => setUser(e.target.value)} />
                    </FloatingLabel>
                </Form.Group>
                <div>
                    <Form.Group controlId="password"  >
                        <FloatingLabel controlId="password" label="Password" className="mb-3">


                            <Form.Control
                                type={showPasswort ? "text" : "password"}
                                placeholder="Password"

                                disabled={!isEditing}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Link onClick={toggleShowPassword} style={{ paddingLeft: '10px', paddingTop: '0px', fontSize: '0.8em' }}>{showPasswort ? "hide" : "show"}</Link>
                        </FloatingLabel>
                    </Form.Group>
                </div>

                <Form.Group controlId="Website">
                    <FloatingLabel controlId="Website" label="Website" className="mb-3">
                        <Form.Control
                            onClick={() => navigator.clipboard.writeText(website)}
                            placeholder="Website"
                            disabled={!isEditing}
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                        />
                    </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="comment" >
                    <FloatingLabel controlId="Comments" label="Comments">
                        <Form.Control 
                            onClick={() => navigator.clipboard.writeText(comment)}
                            as="textarea"
                            disabled={!isEditing}
                            value={comment}
                            placeholder="Leave a comment here"
                            style={{ height: '200px' , fontSize: '12px'}}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </FloatingLabel>
                </Form.Group>

                <Button className='mt-4' disabled={!isEditing} variant="primary" type="submit">Save
                </Button>
            </Form>
        </Modal.Body>
    </>

    )
}