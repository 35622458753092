import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";

const firebaseConfig = {
  apiKey: "AIzaSyA-syAS_sFcRY9xBTeIjSNuY2qxtLLJ6JQ",
  authDomain: "passsafe-6a5d8.firebaseapp.com",
  databaseURL: "https://passsafe-6a5d8.firebaseio.com",
  projectId: "passsafe-6a5d8",
  storageBucket: "passsafe-6a5d8.appspot.com",
  messagingSenderId: "765354628458",
  appId: "1:765354628458:web:a049d88399b8a2451a0113",
  measurementId: "G-P739DGYVJ4"
};

const firebaseConfigAnalytics = {
  apiKey: "AIzaSyA4meGnmwlX1aDMHZk9YBiRdSu3celfxuc",
  authDomain: "analytics-394e7.firebaseapp.com",
  databaseURL: "https://analytics-394e7-default-rtdb.firebaseio.com",
  projectId: "analytics-394e7",
  storageBucket: "analytics-394e7.appspot.com",
  messagingSenderId: "345393928403",
  appId: "1:345393928403:web:7d7b775ae6714c2de29c26",
  measurementId: "G-M9Q6RCK7H7"
};


const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const database = app.database();


const firebaseAppAnalytics = firebase.initializeApp(firebaseConfigAnalytics, 'analytics');
const dbAnalytics = firebaseAppAnalytics.database();



 export {dbAnalytics, auth, database}