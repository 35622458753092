
import { dbAnalytics } from '../firebase'
import { ref, get, set } from 'firebase/database';
import moment from 'moment/moment';

export function increaseCounter(counterName){
    const today = moment().format("YYYY-MM-DD")
    get(ref(dbAnalytics, 'stats/pw7/' + today + '/' + counterName)).then(snap => {
      let counter = parseInt(snap.val()) ?? 0
      if (isNaN(counter)) counter = 0
      set(ref(dbAnalytics, 'stats/pw7/' + today + '/' + counterName), counter + 1)
    })
}