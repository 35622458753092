import { useEffect, useRef, useState } from 'react';
import { Card, Form, Button, Container } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext'
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { COLORS } from './colors';
import { localizedMessage } from './Localisation'
import { database } from '../firebase'
import { ref, onValue, set, remove } from 'firebase/database';

const pw_bg_dark = '#0A4D68'
const pw_light_blue = '#05BFDB'
const pw_light_green = '#00FFCA'

export default function PasswordRecovery() {

    const emailRef = useRef()
    const { currentUser, recover } = useAuth()
    const [error, setError] = useState()
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [allowPasswordRecovery,setAllowPasswordRecovery] = useState(true)


    // Das ist gar nicht so einfach, weil der User ja gar nicht angemeldet ist....
    // useEffect(() => {
    //     onValue(ref(database, 'users/' + currentUser.uid + '/settings/allow_password_recovery'), (snap) => {
    //         setAllowPasswordRecovery(snap.val() ?? true)
    //       })

    // },[])



    async function handleSubmit(e) {
        e.preventDefault()
        try {
            setLoading(true) // Wird ignoriert. Ist das ein PrellProblem?
            console.log("Sending recovery link")
            try {
                await recover(emailRef.current.value)
                setSuccess(true)
            } catch (e) {
                setError(localizedMessage(e.code))
            }
        }
        catch (e) {
            setError(localizedMessage(e.code))
        }
        setLoading(false)
    }
    return (
        <>
            <div className='text-center mb-4'>
                <span style={{ color: pw_light_green }}>Passwords</span>
                <span style={{ marginLeft: '10px', color: 'white', fontSize: '1.7em' }}>7</span>
            </div>
            <Container style={{ maxWidth: '500px' }}>
                <Card className='w-100'>
                    <Card.Body>
                        <h2 className='text-center mb-4'>Password Recovery</h2>
                        {currentUser && currentUser.email}
                        {error && <Alert variant='danger'>{error}</Alert>}
                        {success && <Alert variant='success'>We have sent a password reset-link to your e-mail address</Alert>}

                        <Form onSubmit={handleSubmit}>
                            <Form.Group id='email' className='mb-4'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type='email' autoComplete="off" ref={emailRef} required></Form.Control>
                            </Form.Group>
                      
                            <div className='text-center'>
                                <Button
                                    disabled={loading}
                                    className='w-50 mt-4'
                                    type='submit'
                                    style={{ backgroundColor: COLORS.bg_dark, borderColor: COLORS.pw_light_green }}>Send Recovery Email</Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card >
            </Container>

      

            <div className='text-center mt-2' style={{ color: COLORS.pw_light_blue, fontSize: '.8em' }}>
                <Link to="/login" style={{ color: COLORS.pw_light_green }}>Login</Link>
            </div>
            <div className='text-center mt-2' style={{ color: COLORS.pw_light_blue, fontSize: '.8em' }}>
                <Link to="/signup" style={{ color: COLORS.pw_light_green }}>Sign up</Link>
            </div>
        </>)
}
