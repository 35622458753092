import React, { useContext, useState, useEffect } from 'react'
import { auth, database } from '../firebase'
import firebase from "firebase/compat/app";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ref, set, get } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment/moment';
import { userAuditLog } from '../components/UserAuditLog';
// eslint-disable-next-line import/no-webpack-loader-syntax
var htmlModule = require('raw-loader!../components/LoginDetectedTemplate.html');
var html = htmlModule.default;
// eslint-disable-next-line import/no-webpack-loader-syntax
var plainModule = require('raw-loader!../components/LoginDetectedTemplatePlain.html');
var plain = plainModule.default;




const AuthContext = React.createContext()
// TEST4711
export function useAuth() {
    return useContext(AuthContext)
}
export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState()
    // const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    function recover(email) {
        console.log(`sending recovery link for ${email}`)
        return auth.sendPasswordResetEmail(email)
    }

    function signup(email, password) {
        console.log(`creating user with ${email}`)
        return auth.createUserWithEmailAndPassword(email, password)
            .then(() => {
                auth.currentUser.sendEmailVerification({
                    handleCodeInApp: true,
                    url: "http://localhost:3000"
                })
                set(ref(database,'userstats/'+auth.currentUser.uid),
                {
                    uid:auth.currentUser.uid,
                    email:auth.currentUser.email,
                    source:'web',
                    created_on:moment().format('YYYY-MM-DD HH:mm:ss'),
                    no_password_recovery:false
                })
            })
    }

    function login(email, password) {
        console.log(`login user in with ${email} `)
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logGlobal(message) {
        const today = moment().format("YYYY-MM-DD")
        const time = moment().format("YYYY-MM-DD HH:mm:ss")
        const messageLong = "[PW7] " + time + " " + message
        const uuid = uuidv4()
        console.log("today:", messageLong)
        set(ref(database, 'logging_global/' + today + '/' + uuid), { message: messageLong })
    }
    // function logAudit(message) {
    //     const today = moment().format("YYYY-MM-DD")
    //     const time = moment().format("YYYY-MM-DD HH:mm:ss")
    //     const uid = currentUser.uid
    //     if (!uid) { return }
    //     console.log("today:", today)
    // }
    function loginUpWithGoogle() {
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        // const appleProvider = firebase.auth.OAuthProvider('apple.com');
        // appleProvider.addScope('email');
        // appleProvider.addScope('name');
        return auth.signInWithPopup(googleProvider)

    }
    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }
    async function sendLoginDetectedMail(mail, uid) {
        // TODO: Check settings
        // TODO: Create raw text
        // TODO: Insert uid and mail address
        // TODO: Show default settings on an onboarding page
        // TODO: Only send one message per hour.
        // Check users settings
        let send_email_on_signin = true
        const snap = await get(ref(database, 'users/' + uid + '/settings/email_on_signin'))
        const sendMailNotification = snap.val() ?? true;
        console.log(snap.val())
        if (!sendMailNotification) {
            console.log("email_on_signin on false - not sending.")
            return
        }

        // register when we last sent a mail
        const snap1 = await get(ref(database, 'users/' + uid + '/settings/last_login_notification_sent_at/'))
        const lastsent = snap1.val() ?? 0;
        var d = new Date();
        var timestamp = Math.round(d.getTime() / 1000);
        if ((timestamp - lastsent) < 120) {
            console.log("message was just sent - not sending again.")
            return
        }
        set(ref(database, 'users/' + uid + '/settings/last_login_notification_sent_at/'), timestamp)
        var htmlCode = replaceAll(html, "MAIL_RECIPIENT", mail)
        htmlCode = replaceAll(htmlCode, "UID", uid)
        var plainCode = replaceAll(plain, "MAIL_RECIPIENT", mail)
        plainCode = replaceAll(plainCode, "UID", uid)
        // add to Mail Q
        if (send_email_on_signin) {
            const mailId = uuidv4()
            const mailPath = 'mailqueue/' + mailId
            set(ref(database, mailPath), {
                'uuid': mailId,
                'time_created': moment().format("YYYY-MM-DD HH:mm:ss"),
                'message': plainCode,
                'html': htmlCode,
                'recipient': mail,
                'uid': uid,
                'title': '[Passwords7] Login Detected',
                'origin': 'Chrome Plug-In'
            })
        }
    }

    function loginUpWithApple() {
        const provider = new firebase.auth.OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        return auth.signInWithPopup(provider)

    }

    useEffect(() => {

        logGlobal("Observing auth state")

        const foo = searchParams.get("initialize")
        const unsubscribe = auth.onAuthStateChanged(user => {
            console.log("Current User", user?.uid)
            console.log("Current User Email Verified", user?.emailVerified)
            setCurrentUser(user)
            if (user) {
                console.log(user.emailVerified)
                if (user.emailVerified) {
                    sendLoginDetectedMail(user.email, user.uid)
                    userAuditLog(user.uid,"User has logged in.")
                    navigate('/main')
                } else {
                    navigate('/please_check_mail')
                }
            } else {
                navigate('/login')
            }

        })
        return unsubscribe

    }, [])

    const value = {
        currentUser,
        signup,
        login,
        loginUpWithGoogle,
        loginUpWithApple,
        recover
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}


