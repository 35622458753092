
import { database } from "../firebase"
import { ref, onValue, set, remove, get } from 'firebase/database';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

function userAuditLog(uid, message) {
    console.log("Logging for user", uid, message)
    const uuid = uuidv4()
    const timestamp = moment().format("YYYY-MM-DD HH:mm:ss")
    set(ref(database, 'user_logs/' + uid + "/" + uuid), timestamp + " " + message)

}
export { userAuditLog }
